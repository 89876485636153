import { Box, Container, Typography } from "@material-ui/core"
import React from "react"
import LinkButton from "../components/link-button"
import Section from "../components/section"
import SEO from "../components/seo"
import MainLayout from "../layouts/mainLayout"

interface LocationProps {
  location: Location;
}

const NotFoundPage: React.FC<LocationProps> = (props) => (
  <MainLayout>
    <SEO title="404: Not found" location={props.location} />
    <Section>
      <Container maxWidth="md">
        <Box py={8}>
          <Typography align="center">
            <h1>NOT FOUND</h1>
            <p>The content you were looking for no longer exists.</p>
            <LinkButton variant="contained" color="secondary" size="large" url="/" >
              Return To Home Page
          </LinkButton>
          </Typography>
        </Box>
      </Container>
    </Section>
  </MainLayout>
)

export default NotFoundPage
